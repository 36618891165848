function ready(callbackFunc) {
    if (document.readyState !== 'loading') {
        // Document is already ready, call the callback directly
        callbackFunc();
    } else if (document.addEventListener) {
        // All modern browsers to register DOMContentLoaded
        document.addEventListener('DOMContentLoaded', callbackFunc);
    } else {
        // Old IE browsers
        document.attachEvent('onreadystatechange', function () {
            if (document.readyState === 'complete') {
                callbackFunc();
            }
        });
    }
}
ready(function () {
    (function () {
        const videoCookieTakeOver = document.querySelector('.js-video-cookie-takeover'),
            videoImages = document.querySelectorAll('.js-video-placeholders'),
            closeButton = document.querySelectorAll('.js-close-popup'),
            setCookiebotButton = document.querySelectorAll('.js-set-cookiebot'),
            show_class = ['opacity-1', 'z-50', 'duration-500', 'transition-opacity'],
            hide_class = ['visibility-hidden', 'opacity-0', 'z-0', 'duration-0', 'transition-none', 'pointer-events-none'];

        closeButton.forEach((element) => {
            element.addEventListener('click', (event) => {
                event.preventDefault();
                hideCookieTakeOver();
            });
        });

        setCookiebotButton.forEach((element) => {
            element.addEventListener('click', (event) => {
                event.preventDefault();
                setCookiebotMarketing();
            })
        });

        attachVideoPopUps();

        function attachVideoPopUps() {
            Array.from(videoImages).forEach((element, index) => {
                let currentVideoId = videoImages[index].dataset.videoId;
                let videoSrc = videoImages[index].dataset.videoSrc || undefined;
                let isVideo23 = (videoImages[index].dataset.video23 == "true") ? "&autoPlay=1" : "?autoplay=1";
                if (videoSrc) {
                    element.addEventListener('click', function () {
                        if (getCookiebotMarketing()) {
                            if (!isOverlay(element)) {
                                element.querySelector('iframe').setAttribute('src', videoSrc + isVideo23);
                                element.querySelector('iframe').setAttribute('ID', currentVideoId);
                                element.querySelector('.z-20').remove();
                            } else {
                                window.FullscreenTakeover.openVideo(videoSrc, isVideo23);
                            }
                        } else {
                            showCookieTakeOver();
                        }
                    });
                }
            });
        }

        function isOverlay(element) {
            let parent = element.closest('.vlx-container');
            let isOverlayMobile = parent ? parent.dataset.isOverlayMobile : false;
            let isOverlayDesktop = parent ? parent.dataset.isOverlayDesktop : true;

            return isOverlayMobile == true && window.innerWidth < 640 || isOverlayDesktop == true && window.innerWidth >= 640;
        }

        function hideCookieTakeOver() {
            videoCookieTakeOver.classList.remove(...show_class);
            videoCookieTakeOver.classList.add(...hide_class);
        }

        function showCookieTakeOver() {
            videoCookieTakeOver.classList.remove(...hide_class);
            videoCookieTakeOver.classList.add(...show_class);
        }

        function getCookiebotMarketing() {
            let cookieConsent = (window.Cookiebot) ? window.Cookiebot.consent.marketing : true;
            return cookieConsent;
        }

        function setCookiebotMarketing() {
            const consentOfPreferences = window.Cookiebot.consent.preferences,
                consentOfNecessary = window.Cookiebot.consent.necessary;

            window.Cookiebot.submitCustomConsent(
                consentOfPreferences,
                consentOfNecessary,
                true
            );
            hideCookieTakeOver();
        }
    })();
});
